import {Action, ButtonLink, ButtonLinkCancel, ButtonValidate, ElementList, ElementListSize, FieldBlock, FlexContentSpacing, FormLayoutRows, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {SegmentField} from "../../../api/model/audience/segment/SegmentField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SearchItems, TagSegmentRow} from "../../snippet";

type ModalSelectSegmentsProps = {
    active: boolean;
    preSelectedSegments: Segment[];
    title?: string;
    onSubmit: (values: Segment[]) => void;
    onClose: () => void;
};

const ModalSelectSegments: FunctionComponent<ModalSelectSegmentsProps> = ({active, preSelectedSegments, title, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [isLoading, setLoading] = useState(false);
    const [segments, setSegments] = useState<Segment[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const [highlightedIds, setHighlightedIds] = useState<number[]>([]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setSegments(await session.getSegments());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("segments", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (!active) return;
        setSelectedSegments([...preSelectedSegments]);
    }, [active, preSelectedSegments]);

    const handleAddSegments = (items: Segment[]) => {
        setSelectedSegments((prevSelected) => ([...items, ...prevSelected]));
        setHighlightedIds(items.map((it) => it.id));
        setTimeout(() => {
            setHighlightedIds([]);
        }, 1000);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={title || textSegments("modal.select.title")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                    <SearchItems
                        items={segments}
                        selectedItems={selectedSegments}
                        searchField={SegmentField.NAME}
                        onSubmit={handleAddSegments}
                        loading={isLoading}
                    />
                    <FieldBlock
                        label={textSegments("modal.select.selected", {count: selectedSegments.length})}
                        actions={
                            <ButtonLink onClick={() => setSelectedSegments([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>
                        }
                    >
                        <ElementList placeholder={textSegments("modal.select.search_result")} size={ElementListSize.BIG}>
                            {selectedSegments.map((item) =>
                                <TagSegmentRow
                                    key={item.id}
                                    segment={item}
                                    isHighlighted={highlightedIds.some((id) => item.id === id)}
                                    onRemove={() => setSelectedSegments((prevState) => prevState.filter((it) => it.id !== item.id))}
                                />
                            )}
                        </ElementList>
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={() => onSubmit(selectedSegments)}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalSelectSegments;
