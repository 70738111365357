import {
    Action,
    AlertSeverity,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {AdsAccess} from "../../../api/model/audience/ads/AdsAccess";
import {AdsAccessConfigurationField} from "../../../api/model/audience/ads/AdsAccessConfigurationField";
import {AdsAccessField} from "../../../api/model/audience/ads/AdsAccessField";
import {AdsAccessSearchQuery} from "../../../api/model/audience/ads/AdsAccessSearchQuery";
import {Partner} from "../../../api/model/partner/Partner";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SearchItems, TagPartnerRow} from "../../snippet";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {FormLayoutMessage} from "../../../common/component/snippet";

type ModalAddSharedTaxonomyPartnersProps = {
    active: boolean;
    access: AdsAccess;
    onClose: (refresh: boolean) => void;
};

const ModalAddSharedTaxonomyPartners: FunctionComponent<ModalAddSharedTaxonomyPartnersProps> = ({active, access, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textAdsAccess} = useTranslation(TranslationPortalFile.ADS_ACCESS);
    const [isLoading, setLoading] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const FORM_ID = "form-add-shared-taxonomy-partners";
    const [partners, setPartners] = useState<Partner[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
    const [highlightedPartners, setHighlightedPartners] = useState<Partner[]>();
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<string>();

    useEffect(() => {
        if (active) {
            (async () => {
                try {
                    setLoading(true);
                    setSelectedPartners([]);
                    setShowErrors(false);

                    const searchQuery = new AdsAccessSearchQuery().withPublicTaxonomy();
                    const searchResult = await session.restAdsAccess.search(searchQuery);
                    const searchResultPartnerIds = new Set(searchResult.elements.map((element) => element.partner_id));

                    const partners = await session.getPartners();
                    const filteredPartners = partners.filter((partner) => searchResultPartnerIds.has(partner.id) && !(access.configuration.shared_taxonomy || []).includes(partner.id));
                    setPartners(filteredPartners);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("partners", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [active, access.configuration.shared_taxonomy, alert, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({...prevState, "partners": !selectedPartners.length}));
    }, [setErrors, selectedPartners]);

    const handleAddPartners = (partners: Partner[]) => {
        setSelectedPartners((prevState) => [...prevState, ...partners]);
        setHighlightedPartners(partners);
        setTimeout(() => setHighlightedPartners([]), 1000);
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        setSubmitting(true);
        try {
            const partnerIds = Array.from(new Set([
                ...access.configuration[AdsAccessConfigurationField.SHARED_TAXONOMY] || [],
                ...selectedPartners.map((partner) => partner.id)
            ]));
            await session.restAdsAccess.updateSharedTaxonomy(access.id, partnerIds);
            alert.updateWithSuccess(textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SHARED_TAXONOMY}`));
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate(textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SHARED_TAXONOMY}`), e.message);
            }
        } finally {
            setSubmitting(false);
            setShowErrors(false);
        }
    };

    return (
        <>
            <ModalNew onClose={() => onClose(false)} active={active}>
                <ModalHeader>
                    <ModalHeaderTitle title={textAdsAccess("modal.add_shared_taxonomy_partners.title")}/>
                </ModalHeader>
                <ModalContent>
                    <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                        <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                            <SearchItems
                                items={partners}
                                selectedItems={selectedPartners}
                                searchField="nameWithCompany"
                                onSubmit={handleAddPartners}
                                loading={isLoading}
                            />
                            <FieldBlock
                                label={textAdsAccess("modal.partners.selected", {count: selectedPartners.length})}
                                actions={
                                    selectedPartners.length && <ButtonLink onClick={() => setSelectedPartners([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>
                                }
                                content={{direction: formValidator.isError("partners") ? FlexContentDirection.COLUMN : FlexContentDirection.ROW}}
                            >
                                <ElementList placeholder={textAdsAccess("modal.partners.no_selected")} size={ElementListSize.BIG}>
                                    {selectedPartners.map((partner) =>
                                        <TagPartnerRow
                                            key={partner.id}
                                            partner={partner}
                                            isHighlighted={highlightedPartners?.some(({id}) => partner.id === id)}
                                            onRemove={() => setSelectedPartners(selectedPartners.filter(({id}) => partner.id !== id))}
                                        />
                                    )}
                                </ElementList>
                                {formValidator.isError("partners") &&
                                    <FormLayoutMessage message={textAdsAccess("message.partner_required")} small severity={AlertSeverity.DANGER}/>
                                }
                            </FieldBlock>
                        </FormLayoutRows>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <ButtonLinkCancel onClick={() => onClose(false)}/>
                    <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
                </ModalActions>
            </ModalNew>
        </>
    );
};

export default ModalAddSharedTaxonomyPartners;
