import {
    Action,
    AlertSeverity,
    ButtonLink,
    ButtonLinkCancel,
    ButtonSize,
    ButtonStyle,
    ButtonUpload,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentDirection,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";
import {session} from "../../../api/ApiSession";
import {Distribution} from "../../../api/model/audience/distribution/Distribution";
import {DistributionSegmentsStatusUpdate} from "../../../api/model/audience/distribution/DistributionSegmentsStatusUpdate";
import {DISTRIBUTION_TYPES, DistributionType} from "../../../api/model/audience/distribution/DistributionType";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {SegmentField} from "../../../api/model/audience/segment/SegmentField";
import {SegmentLinkExternal} from "../../../api/model/audience/segment/SegmentLinkExternal";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {Status} from "../../../utils/Status";
import {SearchItems, SelectStatus, TagSegmentRow} from "../../snippet";
import ModalConfirmMessage from "../ModalConfirmMessage";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {FormLayoutMessage} from "../../../common/component/snippet";

type ModalManageDistributionSegmentsProps = {
    active: boolean;
    distribution: Distribution;
    linkedSegments: SegmentLinkExternal[];
    onClose: (refresh: boolean) => void;
};

const ModalManageDistributionSegments: FunctionComponent<ModalManageDistributionSegmentsProps> = ({active, distribution, linkedSegments, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDistributions} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const [isShowModalConfirmSave, setShowModalConfirmSave] = useState(false);
    const [isLoadingSegments, setLoadingSegments] = useState(false);
    const [isLoadingImport, setLoadingImport] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const FORM_ID = "form-manage-distribution-segments";
    const [segments, setSegments] = useState<Segment[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const [highlightedSegments, setHighlightedSegments] = useState<Segment[]>([]);
    const [distributionType, setDistributionType] = useState<DistributionType>(DistributionType.SPECIFIC_SEGMENTS);
    const [status, setStatus] = useState<string>(Status.OPEN.name);
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<string>();

    useEffect(() => {
        if (active) {
            setSelectedSegments([]);
            setShowErrors(false);
            (async () => {
                try {
                    setLoadingSegments(true);
                    const segments = await session.getSegments();
                    const activeSegments = segments.filter((it) => it.active);
                    setSegments(activeSegments);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("segments", e.message);
                    }
                } finally {
                    setLoadingSegments(false);
                }
            })();
        }
    }, [active, alert, setShowErrors]);

    useEffect(() => {
        if (distributionType === DistributionType.ALL_LINKED_SEGMENTS) {
            setStatus(Status.CLOSE.name);
        }
    }, [distributionType]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            "segments": distributionType === DistributionType.SPECIFIC_SEGMENTS && !selectedSegments.length}
        ));
    }, [distributionType, selectedSegments, setErrors]);

    const handleAddSegments = (segments: Segment[]) => {
        setSelectedSegments((prevState) => [...prevState, ...segments]);
        setHighlightedSegments(segments);
        setTimeout(() => setHighlightedSegments([]), 1000);
    };

    const handleImportFile = (file?: File) => {
        setLoadingImport(true);
        try {
            if (file) {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    const fileResult = evt.target?.result;
                    const workBook = XLSX.read(fileResult, {type: "binary"});
                    const workSheetName = workBook.SheetNames[0];
                    const workSheet = workBook.Sheets[workSheetName];
                    const dataString = XLSX.utils.sheet_to_csv(workSheet);

                    const dataList = dataString.split(/\r\n|\n|,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/).filter((it) => it);
                    const newSegmentsIds: number[] = dataList.map((segmentId) => +segmentId.trim()).filter((segmentId) => (selectedSegments.findIndex((it) => it.id === segmentId) === -1));
                    setSelectedSegments((prevState) => [...segments.filter((it) => newSegmentsIds.includes(it.id)), ...prevState]);
                };
                reader.readAsBinaryString(file);
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("import keywords", e.message);
            }
        } finally {
            setLoadingImport(false);
        }
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleValidate = (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        setShowModalConfirmSave(true);
        setShowErrors(false);
    };

    const handleSubmit = async () => {
        setShowModalConfirmSave(false);
        setSubmitting(true);
        let newDistributionSegmentsStatusUpdate = new DistributionSegmentsStatusUpdate();
        newDistributionSegmentsStatusUpdate.link_external_name = distribution.name;
        newDistributionSegmentsStatusUpdate.active = status === Status.OPEN.name;
        switch (distributionType) {
            case DistributionType.ALL_PUBLIC_SEGMENTS:
                newDistributionSegmentsStatusUpdate.segments = segments.filter((it) => !it.private).map((it) => it.id);
                newDistributionSegmentsStatusUpdate.all_segment = false;
                break;
            case DistributionType.SPECIFIC_SEGMENTS:
                newDistributionSegmentsStatusUpdate.segments = selectedSegments.map((it) => it.id);
                newDistributionSegmentsStatusUpdate.all_segment = false;
                break;
            case DistributionType.ALL_LINKED_SEGMENTS:
                newDistributionSegmentsStatusUpdate.segments = [];
                newDistributionSegmentsStatusUpdate.all_segment = true;
                break;
        }
        try {
            await session.restDistribution.updateSegmentsStatus(newDistributionSegmentsStatusUpdate);
            if (newDistributionSegmentsStatusUpdate.all_segment) {
                alert.updateWithSuccess(textDistributions("message.all_linked_segments", {count: linkedSegments.length}));
            } else {
                alert.updateWithSuccess(textDistributions("message.count_segments", {count: newDistributionSegmentsStatusUpdate.segments.length}));
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("distribution's segments", e.message);
            }
        } finally {
            setSubmitting(false);
            onClose(true);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active} loading={false}>
            <ModalHeader>
                <ModalHeaderTitle title={textDistributions("modal.manage_segments", {distribution: distribution.label || distribution.name})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleValidate} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns>
                            <FieldBlock label={textDistributions("field.distribution")}>
                                <Select
                                    value={distributionType}
                                    options={DISTRIBUTION_TYPES.map((it) => ({label: textDistributions(`field.distribution_type.${it}`), value: it}))}
                                    onChange={(option) => setDistributionType(option?.value as DistributionType)}
                                />
                            </FieldBlock>
                            <FormLayoutColumns columns={2}>
                                <FieldBlock label={textDistributions("field.status")}>
                                    <SelectStatus
                                        value={status}
                                        statuses={distributionType === DistributionType.ALL_LINKED_SEGMENTS ? [Status.CLOSE] : [Status.OPEN, Status.CLOSE]}
                                        onChange={(status) => setStatus(status!.name)}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                        </FormLayoutColumns>
                        {distributionType === DistributionType.SPECIFIC_SEGMENTS &&
                            <>
                                <FormLayoutSeparator/>
                                <FieldBlock label={textDistributions("modal.search_segment_to_manage")} required>
                                    <SearchItems
                                        items={segments}
                                        selectedItems={selectedSegments}
                                        searchField={SegmentField.NAME}
                                        onSubmit={handleAddSegments}
                                        loading={isLoadingSegments}
                                    />
                                </FieldBlock>
                                <FieldBlock label={textDistributions("field.import_csv")} content={{noFullWidth: true}}>
                                    <ButtonUpload
                                        label={textDistributions(isLoadingImport ? "modal.import_button_loading" : "modal.import_button")}
                                        onChange={handleImportFile}
                                        style={ButtonStyle.PRIMARY_MIDNIGHT}
                                        size={ButtonSize.SMALL}
                                        disabled={isLoadingImport}
                                    />
                                </FieldBlock>
                                <FieldBlock
                                    label={textDistributions("modal.selected", {count: selectedSegments.length})}
                                    actions={<ButtonLink onClick={() => setSelectedSegments([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>}
                                    content={{direction: formValidator.isError("segments") ? FlexContentDirection.COLUMN : FlexContentDirection.ROW}}
                                >
                                    <ElementList placeholder={textDistributions("modal.add_segments_for_platform")} size={ElementListSize.BIG}>
                                        {selectedSegments.map((segment) =>
                                            <TagSegmentRow
                                                key={segment.id}
                                                segment={segment}
                                                isHighlighted={highlightedSegments.some(({id}) => segment.id === id)}
                                                onRemove={() => setSelectedSegments((prevState) => prevState.filter((it) => it.id !== segment.id))}
                                            />
                                        )}
                                    </ElementList>
                                    {formValidator.isError("segments") &&
                                        <FormLayoutMessage message={textDistributions("message.segment_required")} small severity={AlertSeverity.DANGER}/>
                                    }
                                </FieldBlock>
                            </>
                        }
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
            <ModalConfirmMessage
                active={isShowModalConfirmSave}
                message={textDistributions(status === Status.OPEN.name ? "message.confirm_open_segments" : "message.confirm_close_segments")}
                confirm={handleSubmit}
                confirmAction={status === Status.OPEN.name ? Action.OPEN : Action.CLOSE}
                cancel={() => setShowModalConfirmSave(false)}
            />
        </ModalNew>
    );
};

export default ModalManageDistributionSegments;
